import { Button, Divider, List, ListItemButton } from '@mui/material';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {
  createTicker,
  deleteTicker,
  saveTicker,
  selectCurrentTicker,
  selectTickers,
  setCurrentTicker
} from 'store/tickersSlice';
import { Ticker } from 'models/ticker';
import TickerListItem from './TickerListItem';
import React, { useState } from 'react';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import TickerDialog from './TickerDialog';

const TickersList: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [tickerToDelete, setTickerToDelete] = useState<Ticker | null>(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const tickers = useStoreSelector(selectTickers);
  const selectedTicker = useStoreSelector(selectCurrentTicker);

  const dispatch = useStoreDispatch();

  const handleCreateNewPage = () => {
    setDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false);
    setTickerToDelete(null);
  };

  const handleDelete = (ticker: Ticker, event: React.MouseEvent) => {
    event.stopPropagation();
    setTickerToDelete(ticker);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (tickerToDelete) {
      dispatch(deleteTicker({ ticker: tickerToDelete }));
    }
    setConfirmDialogOpen(false);
  };

  const handleSelectTicker = (ticker: Ticker) => {
    dispatch(setCurrentTicker(ticker));
  };

  const handleCreateTicker = (item: string) => {
    dispatch(createTicker({ symbol: item }));
    setDialogOpen(false);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <List
        className="tickers-list"
        sx={{
          height: '100%',
          overflow: 'auto',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '10px',
          paddingBottom: 0
        }}>
        <div className="tickers-list-header">
          <Button
            variant="text"
            size="small"
            startIcon={<NoteAddIcon />}
            onClick={handleCreateNewPage}>
            New Ticker
          </Button>
          <Divider />
        </div>
        {tickers &&
          tickers.map((ticker) => {
            return (
              <ListItemButton
                disableRipple
                key={ticker.id}
                onClick={() => handleSelectTicker(ticker)}
                className={selectedTicker?.id === ticker.id ? 'selected' : ''}
                divider={false}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexGrow: 0,
                  padding: '0 6px 0 6px'
                }}>
                <TickerListItem ticker={ticker} onDelete={handleDelete} />
              </ListItemButton>
            );
          })}
      </List>
      <TickerDialog open={isDialogOpen} onSubmit={handleCreateTicker} onCancel={handleCancel} />

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        title="Delete ticker"
        text="Are you sure you want to permanently delete this ticker? This action cannot be undone."
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

export default TickersList;
