import { IconButton, ListItemText, Rating } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { Ticker } from 'models/ticker';

interface TickerListItemProps {
  ticker: Ticker;
  onDelete: (ticker: Ticker, event: React.MouseEvent) => void;
}

const TickerListItem: React.FC<TickerListItemProps> = ({ ticker, onDelete }) => {
  const secondaryProps = {
    fontSize: 11
  };

  const handleDelete = (event: React.MouseEvent) => {
    onDelete(ticker, event);
  };

  return (
    <React.Fragment>
      <ListItemText
        primary={ticker.ticker}
        secondaryTypographyProps={secondaryProps}></ListItemText>
      <Rating value={ticker.rating} size="small" readOnly></Rating>
      <div className="menu">
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default TickerListItem;
