import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Ticker } from 'models/ticker';
import tickerService from 'services/tickerService';
import { RootState } from './store';

export interface TickersState {
  tickers: Ticker[];
  currentTicker: Ticker | undefined;
  editingTicker?: Ticker;
}

const initialState: TickersState = {
  tickers: [],
  currentTicker: undefined
};

export const loadTickers = createAsyncThunk<Ticker[], { symbol?: string; searchString?: string }>(
  'tickers/load',
  async ({ symbol, searchString }, thunkApi) => {
    try {
      const response = await tickerService.loadTickers(symbol, searchString);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveTicker = createAsyncThunk<Ticker | null, Ticker, { state: RootState }>(
  'tickers/save',
  async (ticker, { getState }) => {
    const { tickers } = getState();
    if (tickers.editingTicker) {
      const tickerToSave = { ...tickers.editingTicker, note: ticker.note };
      const response = await tickerService.save(tickerToSave);
      return response.data;
    } else {
      return null;
    }
  }
);

export const updateRating = createAsyncThunk<Ticker | null, number | null, { state: RootState }>(
  'tickers/updateRating',
  async (rating, { getState }) => {
    const { tickers } = getState();
    if (tickers.currentTicker) {
      const tickerToSave = { ...tickers.currentTicker, rating: rating };
      const response = await tickerService.save(tickerToSave);
      return response.data;
    } else {
      return null;
    }
  }
);

export const createTicker = createAsyncThunk<Ticker, { symbol: string }, { state: RootState }>(
  'tickers/create',
  async ({ symbol }, { getState }) => {
    const response = await tickerService.create(symbol);
    return response.data;
  }
);

export const saveTickerSymbol = createAsyncThunk<Ticker, { symbol: string }, { state: RootState }>(
  'tickers/save-symbol',
  async ({ symbol }, { getState }) => {
    const { tickers } = getState();
    const tickerToSave = { ...tickers.currentTicker, ticker: symbol } as Ticker;
    const response = await tickerService.save(tickerToSave);
    return response.data;
  }
);

export const deleteTicker = createAsyncThunk<
  { deletedItemId: number },
  { ticker: Ticker },
  { state: RootState }
>('watchlist/item/delete', async ({ ticker }, { getState }) => {
  const response = await tickerService.delete(ticker);
  if (response.status === 200) {
    return { deletedItemId: ticker.id };
  }
  throw new Error('Failed to delete watchlist item ' + ticker.id);
});

export const tickerSlice = createSlice({
  name: 'tickers',
  initialState,
  reducers: {
    setCurrentTicker: (state, action) => {
      state.currentTicker = action.payload;
    },
    setEditingTicker: (state, action) => {
      state.editingTicker = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadTickers.fulfilled, (state, action) => {
        state.tickers = action.payload;
        state.currentTicker = undefined;
      })
      .addCase(saveTicker.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }

        const index = state.tickers.findIndex((t) => t.id === action.payload?.id);
        if (index !== -1) {
          state.tickers.splice(index, 1, action.payload);
        } else {
          state.tickers.push(action.payload);
        }

        if (state.currentTicker?.id === action.payload.id) {
          state.currentTicker = action.payload;
        }
      })
      .addCase(updateRating.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }

        const index = state.tickers.findIndex((t) => t.id === action.payload?.id);
        if (index !== -1) {
          state.tickers.splice(index, 1, action.payload);
        } else {
          state.tickers.push(action.payload);
        }

        if (state.currentTicker?.id === action.payload.id) {
          state.currentTicker = action.payload;
        }
      })
      .addCase(saveTickerSymbol.fulfilled, (state, action) => {
        const index = state.tickers.findIndex((t) => t.id === action.payload?.id);
        if (index !== -1) {
          state.tickers.splice(index, 1, action.payload);
        } else {
          state.tickers.push(action.payload);
        }

        if (state.currentTicker?.id === action.payload.id) {
          state.currentTicker = action.payload;
        }
      })
      .addCase(deleteTicker.fulfilled, (state, action) => {
        const index = state.tickers.findIndex((item) => item.id === action.payload.deletedItemId);
        if (index !== -1) {
          state.tickers.splice(index, 1);
        }

        if (state.currentTicker?.id === action.payload.deletedItemId) {
          state.currentTicker = undefined;
        }
      })
      .addCase(createTicker.fulfilled, (state, action) => {
        const index = state.tickers.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.tickers.splice(index, 1, action.payload);
        } else {
          state.tickers.push(action.payload);
        }
        state.currentTicker = action.payload;
      });
  }
});

export const selectTickers = (state: { tickers: TickersState }) => state.tickers.tickers;
export const selectCurrentTicker = (state: { tickers: TickersState }) =>
  state.tickers.currentTicker;

export const { setCurrentTicker, setEditingTicker } = tickerSlice.actions;

export default tickerSlice.reducer;
